import React from "react"

import Layout from "../components/Layout/layout"

const NotFoundPage = () => (
  <Layout>
  </Layout>
)

export default NotFoundPage
